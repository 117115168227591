<template>
  <div class="bulkComm-detail" style="background-color: #f8fcff">
    <BannerTItle :commodityDetailData="commodityDetailData"/>
    <div class="content">
      <div class="cTop">
        <div class="icon"></div>
        <span class="title">商品介绍照片</span>
      </div>
      <div class="cBottom" ref="imgView">
        <ul>
          <li v-for="item in commodityDetailData.photoList" :key="item.fileId">
           <img :src="item.path" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BannerTItle from "./bannerTItle.vue";
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { mapState } from "vuex";
export default {
  name: "fillInTheOrder",
  components: {
    BannerTItle
  },
  data() {
    return {
			
    };
  },
  computed: {
    ...mapState({
      commodityDetailData: state => {
        return state.bulkCommodity.commodityDetailData;
      },
    })
  },
  created() {
    this.fetch();
  },
	mounted(){
		
	},
  methods: {
    fetch() {
      this.$store.dispatch("bulkCommodity/getCommodityDetail", { ...this.$route.query }).then(() => {
				const ViewerDom = this.$refs.imgView;
				const viewer = new Viewer(ViewerDom, {
					// inline: true,
					title: false,
					fullscreen: false,
					// zoomRatio: 3,
					// zIndexInline: 10,
					// url: 'data-original',
					// viewed () {
					// 	viewer.zoomTo(0.6)
					// }
				})
			})
    },
  }
};
</script>

<style lang="less" scoped>
.clearFix() {
  *zoom: 1;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.content {
  width: 1275px;
  height: 460px;
  margin: 100px auto 36px;
  box-shadow: 0px 0px 16px 0px rgba(71, 86, 159, 0.2);
  border-radius: 5px;
  background: #ffffff;
  .cTop {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #dde6f2;
    .clearFix();
    .icon {
      width: 46px;
      height: 46px;
      float: left;
      margin: 14px 0 0 23px;
      background: url(/img/details/content.png) no-repeat left;
      background-size: 46px;
    }
    > span {
      width: 121px;
      height: 100%;
      float: left;
      line-height: 70px;
      margin-left: 10px;
      font-size: 20px;
      font-family: SourceHanSansCN;
      font-weight: 300;
      color: #333333;
    }
  }
  .cBottom {
    width: 100%;
    ul {
      li {
        width: 21.8%;
        float: left;
        margin: 1.62% 0 0 2.5%;
          img {
            width: 100%;
            height: 100%;
          }
      }
    }
  }
}
</style>