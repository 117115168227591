<template>
  <div class="banner">
    <div class="type">
      <div class="type1">
        <span class="p1">品名名称：</span>
        <span class="p2">{{commodityDetailData.name}}</span>
      </div>
      <div class="type2">
        <span class="p3">品名类型：</span>
        <i class="pType">{{commodityDetailData.commoditytype}}</i>
        <!-- <span class="p4">业务员：</span>
        <i class="uName">{{commodityDetailData.publisher}}</i> -->
      </div>
      <div class="type3">
        <span class="p5">销售备注：</span>
        <p class="cont">
          {{commodityDetailData.remark}}
        </p>
      </div>
      <div class="join" v-if="isShowBid">
        <i></i>
        <span>参与投标</span>
      </div>
      <div class="nav">
        <div class="nav-item nav1">
          <span class="left">预计重量</span>
          <span class="right"><em>{{commodityDetailData.weight}}</em>吨</span>
        </div>
        <div class="nav-item nav2">
          <span class="left">最低价格</span>
          <span class="right"><em>{{commodityDetailData.minPrice}}</em>元/t</span>
        </div>
        <div class="nav-item nav3">
          <span class="left">当前最高价</span>
          <span class="right"><em>{{commodityDetailData.nowMaxPrice}}</em>元/t</span>
        </div>
        <div class="nav-item nav3">
          <span class="left">最低加价</span>
          <span class="right"><em>{{commodityDetailData.minPlusPrice}}</em>元/t</span>
        </div>
        <div class="nav-item nav4">
          <span class="left">截止时间</span>
          <span class="right"><em>{{commodityDetailData.endTime}}</em></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bannerTItle",
  props: ['commodityDetailData', 'isShowBid'],
};
</script>

<style lang="less" scoped>
.clearFix() {
  *zoom: 1;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.banner {
  position: relative;
  height: 220px;
  background-image: url(/img/details/banner.png);
  background-size: 100% 220px;
  opacity: 0.8;
  .type {
    width: 66.6%;
    height: 100%;
    margin: 0 auto;
    padding-top: 40px;
		position:relative;
    .clearFix();

    .p1 {
      width: 120px;
      height: 22px;
      font-size: 22px;
      font-family: SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
    }
    .p2 {
      width: 124px;
      height: 24px;
      font-size: 24px;
      font-family: SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
    }
    .type2 {
      margin-top: 20px;
      // float: left;
      .p3 {
        margin-top: 27px;
        height: 48px;
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #b9dbf7;
      }
      i {
        height: 16px;
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #ffffff;
        font-style: normal;
      }
      .p4 {
        height: 16px;
        margin-left: 250px;
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #b9dbf7;
      }
    }
    .type3 {
      margin-top: 10px;
      .p5 {
        height: 48px;
        float: left;
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #b9dbf7;
      }
      > p {
        height: 16px;
        font-size: 16px;
        float: left;
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #ffffff;
      }
    }
    .join {
      position: absolute;
      width: 180px;
      height: 50px;
      top: 65px;
      right: 360px;
      background: linear-gradient(90deg, #ff934c 0%, #fc686f 100%);
      border-radius: 30px;
      border: 5px solid #5b87c0;
      cursor: pointer;
      .clearFix();
      > i {
        float: left;
        margin-left: 23px;
        width: 30px;
        height: 100%;
        margin-top: 10px;
        background: url(/img/details/fly.png) no-repeat;
        background-size: 30px;
      }
      > span {
        float: right;
        margin-right: 31px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .nav {
      position: absolute;
      bottom: -20px;
      width: 100%;
      height: 100px;
      > .nav-item {
        float: left;
        margin-right: 1%;
        width: 18.7%;
        height: 100%;
        border-radius: 5px;
        background-color: #e6e6e6;
        text-align: center;
        line-height: 100px;
        box-shadow: 0px 0px 16px 0px rgba(71, 86, 159, 0.2);

        > span {
          font-size: 16px;
          font-family: SourceHanSansCN;
          flex-wrap: 400;
          color: #666;

          > em {
            font-size: 22px;
            font-weight: 700;
            line-height: 100px;
            color: #f34029;
            margin-right: 1px;
          }
        }
        > .left {
          float: left;
          padding-left: 30px;
          margin-left: 15px;
        }
        > .right {
          float: right;
          margin-right: 15px;
        }
				&:last-child{
					 width: 21%;
					 margin-right: 0;
				}
      }
      .nav1 {
        background: #fff url("/img/details/nav1.png");
        background-size: 280px 230px;
        .left {
          background: url(/img/details/1.png) no-repeat no-repeat left;
          background-size: 18px;
        }
      }
      .nav2 {
        background: #fff url("/img/details/nav2.png");
        background-size: 280px 230px;
        .left {
          background: url(/img/details/2.png) no-repeat no-repeat left;
          background-size: 18px;
        }
      }
      .nav3 {
        background: #fff url("/img/details/nav3.png");
        background-size: 280px 230px;
        .left {
          background: url(/img/details/2.png) no-repeat no-repeat left;
          background-size: 18px;
        }
      }

      .nav4 {
        background: #fff url("/img/details/nav4.png");
        background-size: 280px 230px;
        .left {
          background: url(/img/details/3.png) no-repeat no-repeat left;
          background-size: 18px;
        }
      }
    }
  }
}
</style>